<template>
  <div class="reset-password-form">
    <h2
      class="reset-password-form__heading u-typography-gilroy u-text-display  u-text-display--m u-text-display--bold"
    >
      {{ $t('dashboard.landing.createNewPassword') }}
    </h2>
    <u-form class="form" @submit.prevent="onSubmit">
      <u-input
        v-model="password"
        type="password"
        :label="$t('dashboard.resetPasswordPage.label.newPassword')"
        :validators="[validators.REQUIRED]"
        @input="validatePasswordStrength"
      />
      <u-input
        v-model="passwordConfirmed"
        class="form__confirm-password"
        type="password"
        :label="$t('dashboard.resetPasswordPage.label.confirmPassword')"
        :validators="[validators.REQUIRED, confirmPasswordValidator]"
      />

      <div class="form__password-strength password-strength u-typography-gilroy u-text u-text--xs">
        <u-icon v-tooltip="passwordStrengthHint" name="help-circle" />
        {{ $t('dashboard.landing.passwordStrength') }}
        <span class="password-strength__strength" :class="passwordStrengthClasses">{{
          passwordStrengthPhrase
        }}</span>
      </div>

      <u-button class="form__button" type="submit" :loading="loading">{{
        $t('dashboard.landing.button.sigIn')
      }}</u-button>

      <div v-if="formError" class="form__error-message u-typography-gilroy u-text u-text--xs">
        <u-icon name="alert-triangle" />
        {{ formError }}
      </div>
    </u-form>
  </div>
</template>

<script>
import { UButton, UForm, UInput, UIcon } from 'universkin-shared';
import { getValidator } from 'universkin-shared/src/utils/validators';

import { validatable } from '@/mixins/validatable';

const STRONG_PASSWORD_REGEX = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[$#<>()@+-/*=%{}.:,;!?"'])(?=\S+$).{8,}$/;

export default {
  name: 'ResetPasswordForm',
  components: { UButton, UForm, UInput, UIcon },
  mixins: [validatable],
  props: {
    formError: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      password: '',
      passwordConfirmed: '',
      isWeak: true
    };
  },
  computed: {
    confirmPasswordValidator() {
      return getValidator(
        value => this.password === value,
        this.$t('dashboard.resetPasswordPage.passwordsAreDifferent')
      );
    },
    passwordStrengthHint() {
      const mustContainText = this.$t('dashboard.resetPasswordPage.passwordMustContain');
      const noSpacesText = this.$t('dashboard.resetPasswordPage.andNoSpaces');

      return `${mustContainText} $#&lt;&gt;()@+-+/{}:;’.,!?”” ${noSpacesText}`;
    },
    passwordStrengthPhrase() {
      return this.isWeak
        ? this.$t('dashboard.authentication.label.weak')
        : this.$t('dashboard.authentication.label.strong');
    },
    passwordStrengthClasses() {
      return { 'password-strength__strength--weak': this.isWeak };
    }
  },
  methods: {
    onSubmit() {
      this.$emit('submit', this.password);
    },
    validatePasswordStrength(value) {
      this.isWeak = !value.match(STRONG_PASSWORD_REGEX);
      return !!value;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/colors';
@import '~universkin-shared/src/assets/styles/scss/sizes';
@import '../../assets/css/common/tooltips.css';

.reset-password-form {
  &__heading {
    margin: 0 0 10px 0;
  }
}

.form {
  display: flex;
  flex-direction: column;

  &__confirm-password {
    margin-top: 24px;
  }

  &__password-strength {
    margin: 25px 0 25px 0;
  }

  &__error-message {
    color: $color-error;
    --u-icon-color: #{$color-error};
    margin-top: 5px;
    text-align: center;
  }
}

.password-strength {
  &__strength {
    color: $color-success;

    &--weak {
      color: $color-error;
    }
  }
}

@media (min-width: $desktop-start) {
  .reset-password-form {
    max-width: 325px;
  }

  .form {
    &__confirm-password {
      margin-top: 22px;
    }
  }
}
</style>
