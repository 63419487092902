<template>
  <start-page-template>
    <reset-password-form :form-error="formError" :loading="isLoading" @submit="signIn" />
  </start-page-template>
</template>

<script>
import { mapActions } from 'vuex';

import StartPageTemplate from '@/components/templates/StartPageTemplate';
import ResetPasswordForm from '@/components/start-page/ResetPasswordForm';

import { resetPassword } from '@/api';
import { isErrorResponse } from '@/api/selectors';

import { types } from '@/store/types';
import { hasResponseErrors } from '@/utils/storeActionsUtils';

export default {
  name: 'PageResetPassword',
  components: {
    StartPageTemplate,
    ResetPasswordForm
  },
  props: {
    hashedUsername: {
      type: String,
      required: true
    },
    hashedInstant: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      formError: '',
      isLoading: false
    };
  },
  methods: {
    ...mapActions({
      login: types.actions.LOGIN,
      setGlobalLoading: types.actions.SET_LOADING
    }),
    async signIn(password) {
      this.formError = '';

      this.setLoading(true);

      const decryptedUserName = await this.resetPassword(password);

      if (!decryptedUserName) {
        this.setLoading(false);

        return;
      }

      const response = await this.login({
        username: decryptedUserName,
        password
      });

      this.setLoading(false);

      if (response.shouldUseTwoFactorAuthentication) {
        await this.$router.push({
          name: 'TwoFactorAuthentication',
          params: { username: decryptedUserName, password }
        });

        return;
      }

      if (hasResponseErrors(response)) {
        this.formError = this.$t('dashboard.landing.wrongEmailOrPassword');

        return;
      }

      this.$router.push({
        name: 'Dashboard'
      });
    },
    setLoading(value) {
      this.isLoading = value;
      this.setGlobalLoading(value);
    },
    async resetPassword(password) {
      const response = await resetPassword({
        username: this.hashedUsername,
        password,
        instant: this.hashedInstant,
        isPatient: false
      });

      if (isErrorResponse(response)) {
        this.formError = this.$t('dashboard.landing.internetDisconnected');
      }

      const { data } = response;

      return data;
    }
  },
  metaInfo: {
    title: 'Reset Password',
    titleTemplate: 'skinXs - %s'
  }
};
</script>
